import React, { useContext } from 'react'
import {isMobile} from 'react-device-detect'

import Layout from '../layouts/Artist'
import Main from '../views/artists/louis-henderson'
import Intro from '../views/intro'

import background from '../assets/images/louis-henderson/background.png'

import { IntroContext } from '../context'

const Default = () => {
  const { intro, setIntro } = useContext(IntroContext)

  const mainView = (
    <Layout background={background}>
      <Main />
    </Layout>
  )

  const introView = (
    <Intro setIntro={setIntro}/>
  )

  if (!isMobile) {
    if (intro) {
      return introView
    }
  }

  return mainView
}

export default Default
