import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    "&:hover": {
      cursor: 'pointer'
    },
    backgroundColor: '#000'
  },
  vimeoWrapper: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    overflow: "hidden",
   },
   frame: {
    width: "100vw",
    height: "56.25vw", /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    minHeight: "100vh",
    minWidth: "177.77vh", /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
   },
}));

export default function Page(props) {
  const classes = useStyles()

  return (
    <>
      <div className={classes.root}
        onClick={() => props.setIntro(false)}
      >
      <div className={classes.vimeoWrapper} >
        <iframe
          title='bg'
          className={classes.frame}
          src={`https://player.vimeo.com/video/648532508?h=4b9554efa2&background=1&autoplay=1&loop=1&byline=0&title=0`}
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          autoplay muted
        >
        </iframe>
      </div>
      { props.children }
      </div>
    </>
  );
}


