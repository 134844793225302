import React from 'react'
import Typography from '@material-ui/core/Typography'

import Header from '../../../components/sections/Header'
import Text from '../../../components/sections/Text'
import Film from '../../../components/sections/Film'
import Podcast from '../../../components/sections/Podcast'
import Essay from '../../../components/sections/Essay'
import Soundtrack from '../../../components/sections/Soundtrack'
import Gallery from '../../../components/sections/Gallery'
import Footer from '../../../components/sections/Footer'

import { LinkTab } from '../../../components/Link'

import background from '../../../assets/images/louis-henderson/background.png'
import still from '../../../assets/images/louis-henderson/still.png'


import img1 from '../../../assets/images/louis-henderson/1.png' 
import img2 from '../../../assets/images/louis-henderson/2.png' 
import img3 from '../../../assets/images/louis-henderson/3.png' 
import img4 from '../../../assets/images/louis-henderson/4.png' 
import img5 from '../../../assets/images/louis-henderson/5.png' 
import { PinDropSharp } from '@material-ui/icons'

const slides = [
  {
    label: 'a',
    image: img1
  },
  {
    label: 'q',
    image: img2
  },
  {
    label: 's',
    image: img3
  },
  {
    label: 'w',
    image: img4
  },
  {
    label: 'e',
    image: img5
  },
];

export default function Album(props) {

  return (
    <>
      <Header
        name='Louis Henderson'
        title='Evidence of Things Unseen but Heard'
        date='23 November - 19 December 2021'
      />

      <Text>
        <Typography variant='subtitle1'
          paragraph
        >
Evidence of Things Unseen But Heard draws a relation between technologies of state surveillance against black communities in Bristol, the rise of sound system culture, and the exceptional character of “Bristol sound”. Shot around the St Pauls neighbourhood, while reflecting on Bristol’s history, which heavily rests on plantation labour and slavery, Louis Henderson stitches a sonic archaeology through archival photographs of the St Pauls carnival and the direct aftermath of the riots of 1980.
        </Typography>
      </Text>

      <Film 
        url="648502887?h=75aab8612c"
        title="Louis Henderson, Evidence of Things Unseen but Heard (2018) 20 min."
        titleShort="Louis Henderson - Evidence of Things"
      />

      <Essay 
        title="Louis Henderson"
        subtitle="Evidence of Things Unseen but Heard"
       url="/articles/henderson-evidence"
      >
      <Typography
        variant='subtitle1'
        gutterBottom
      >
“…sound and silence. What bridges the two elements is echo, the traces of creation. If sound is birth and silence death, the echo trailing into infinity can only be the experience of life, the source of narrative and a pattern for history.”  
      </Typography>
      <Typography
        variant='subtitle1'
        gutterBottom
      >
<b>Bristol, August 23, 2017</b>
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
A grey and pink sky dashed across with the deep blues of post storm light. Dark rain clouds and a river the colour of lead. At Portishead the boat begins to bear right, from the Mouth of the Severn fed by the North Atlantic, into the River Avon and onwards towards the city of Bristol. Heading down past Clifton and into the Cumberland Basin, eventually the boat enters Bristol Harbour. On the left are the offices of Lloyds Bank, a company that grew to its powerful status from insuring enslaved people and slave ships during the Atlantic Slave Trade.
      </Typography>
      </Essay>

      <Soundtrack 
        title="The Ensemble of the Senses and the Ensemble of the Social"
        subtitle="Louis Henderson in conversation with Lilly Markaki"
        caption=""
        image={still}
        color='#fff'
        url="https://soundcloud.com/felice-moramarco-726293220/louis-henderson-in-conversation-with-lilly-markaki?si=d3e085a3d4d74e3bb9e3364b8cd80579"
      />

      <Gallery 
        slides={slides}
        title='Photographic contact sheets documenting the St Paul’s riots in Bristol in 1980, courtesy of the Bristol Archives.'
      />
      
      <Footer />

    </>
  );
}
